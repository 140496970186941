<template>
  <div>
    <h1>Aguardando Pagamento</h1>
    <p>
      Estamos aguardando a confirmação do seu pagamento pela instituição financeira.
      Enquanto isso, confira alguns artigos importantes em nosso blog.
    </p>
  </div>
</template>
<script>

export default {
  name: "payment-pending-boleto",
  data(){
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
  .no-box-shadow{
		box-shadow: none

  }
	

</style>
