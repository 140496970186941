<template>
  <div>
    <h2 class="custom-title-secondary mb-1">Aguardando Pagamento</h2>
    <p class="custom-normal-text">
      Clique no link abaixo para acessar o seu boleto. Para sua comodidade,
      enviamos uma cópia para o seu email cadastrado.
    </p>

    <v-btn
      class="mb-3 custom-btn-green rounded-0"
      target="_blank"
      :loading="loading"
      @click="downloadBoleto"
      :href="companyPaymentInfo.firstInvoiceLink"
    >
      baixe aqui o seu boleto bancário
    </v-btn>

    <p class="custom-normal-text">
      O processo de confirmação do seu pagamento junto à instituição financeira
      pode levar até 48 horas.
      <br />
      <span class="text-blue">
        Enquanto isso, confira alguns artigos importantes em nosso blog.
      </span>
    </p>
  </div>
</template>
<script>
import { paymentComputed, authComputed, paymentMethods } from '@state/helpers'

export default {
  name: 'payment-pending-boleto',
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...paymentComputed,
    ...authComputed,
  },
  methods: {
    ...paymentMethods,
    downloadBoleto() {
      this.$ga.event({
        eventCategory: this.eventCategoryType(),
        eventAction: 'pagamento',
        eventLabel: 'dados_download_boleto',
        eventValue: 10,
      })
    },
    eventCategoryType() {
      switch (this.currentUser.registration) {
        case 'new':
          return 'Abertura'
          break
        case 'new-mei':
          return 'Abertura MEI'
          break
        case 'migrate':
          return 'Migração'
          break
        case 'migrate-mei':
          return 'Migração MEI'
          break
      }
    },
  },
  async mounted() {
    this.loading = true
    await this.getPaymentInfoByRegistering()
    this.loading = false
  },
}
</script>

<style lang="scss">
@import '@design';
</style>
