<template>
  <div>
    <k-payment-pending-boleto v-show="companyPaymentPedingPaymentBoleto" />
    <k-payment-pending-creditcard
      v-show="companyPaymentPedingPaymentCreditcard"
    />
    <blog />
  </div>
</template>
<script>
import blog from '@components/registering/Blog.vue'
import kPaymentPendingBoleto from './PaymentPendingBoleto.vue'
import kPaymentPendingCreditcard from './PaymentPendingCreditcard.vue'
import longPulling from '@src/services/long-pulling'

import { paymentComputed } from '@state/helpers'

export default {
  name: 'payment-pending',
  components: {
    blog,
    kPaymentPendingBoleto,
    kPaymentPendingCreditcard,
  },
  computed: {
    ...paymentComputed,
  },
  methods: {},
  watch: {
    show: {
      immediate: true,
      handler(vNew) {
        if (vNew) {
          longPulling.start('paymentInfo/getPaymentStep')
          longPulling.sequence('paymentInfo/getPaymentStep', [5, 10, 30, 45])
        } else {
          longPulling.stop('paymentInfo/getPaymentStep')
        }
      },
    },
  },
  beforeDestroy() {
    longPulling.stop('paymentInfo/getPaymentStep')
  },
}
</script>

<style lang="sass" scoped></style>
